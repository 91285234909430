import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    load: true,
}

export const loaderSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.load = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLoader } = loaderSlice.actions

export default loaderSlice.reducer