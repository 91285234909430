import React,{useState,useEffect} from 'react'
import { Link,useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from "axios"
const Payout = () => {

    const {token} = useSelector(state => state.auth);
    const [payoutMethods,setPayoutmethod]=useState([]);

    const fetchPayout = ()=>{
        axios.get("payout",{
            headers: {
                Authorization: 'Bearer '+token,
                Accept: 'application/json'
            }
        })
        .then((res)=>{
            setPayoutmethod(res.data.payout_methods);
        })
        .catch((err)=>{
            console.log(err.message);
        })
    }
    
    useEffect(()=>{
        fetchPayout();
    },[])


    const handlePayoutDelete = (id) => {
        console.log(id)
        if (token != "") {
            axios.delete(`/payout/${id}`, {
                headers: {
                    Authorization: 'Bearer '+token,
                    Accept: 'application/json',
                }
            }).then(response => {
                console.log(response)
                fetchPayout();
            }).catch(errors => {
                console.log(errors.response)
            })
        }
    }

    // console.log("🏆",payoutMethods);
    return (
        <>
        <div className="w-full flex flex-wrap">
        <div className="w-full px-1">
            <div className="card">
                <div className="card-body">
                    <h4 className="card-heading">Payout Method</h4>  <div className='w-1/6'> 
                   <Link to="/payoutcreate" className='bg-purple-600 p-3 w-full flex justify-evenly text-white font-bold rounded right-0'>Create Payout Method</Link>
                   </div>
                  
                    <div className="max-w-full overflow-x-auto">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="w-1/6">Store Id</th>
                                    <th className="w-1/6">Bank Name</th>
                                    <th className="w-1/6">Bank Branch</th>
                                    <th className="w-1/6">Bank Routing Number</th>
                                    <th className="w-1/6">Account Name</th>
                                    <th className="w-1/6">Account Number</th>
                                    <th className='w-1/6'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payoutMethods?.map((item,index) => (
                                   
                                    <tr key={index}>
                                        <td className="w-32">{item.store_id}</td>
                                        <td>
                                            <p className="font-bold">{item.bank_name}</p>
                                        </td>
                                        <td>
                                            <p className="w-45">{item.bank_branch}</p>
                                        </td>
                                        <td>
                                            <p className="w-45">{item.bank_routing_number}</p>
                                        </td>
                                        <td>
                                            <p className="w-45">{item.account_name}</p>
                                        </td>
                                        <td>
                                            <p className="w-45">{item.account_number}</p>
                                        </td>
                                        <td>
                                        <div ><Link onClick={() => handlePayoutDelete(item.id)} className="fas fa-trash ml-4 cursor-pointe text-red-700">Delete</Link></div>
                                        </td>
                                        
                                    
                                    </tr>
                                  
                                ))}
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
           
        </div>
        
          
 
    </div>
        </>
    )
}

export default Payout
