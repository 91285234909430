import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

const PayoutHistoryCreate = () => {
  const { token } = useSelector((state) => state.auth);
  const [errors, setErrors] = useState([]);
  const [methods, setMethods] = useState([]);
  const [payoutMethod, setPayoutMethod] = useState("");
  const [total, setTotal] = useState(0);
  const [items, setItem] = useState([]);

  let history = useHistory();

  const fetchPayout = () => {
    axios
      .get("payout", {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.payout_methods);
        console.log("🏆", res.data.items);
        setItem(res.data.items);
        setMethods(res.data.payout_methods);
        setTotal(res.data.total_amount);

      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleSubmit = () => {
    axios
      .post(
        "payoutstore",
        {
          payout_method_id: payoutMethod,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        history.push("/payouthistory");
      })
      .catch((errors) => {
        console.log(errors.response);
        if (errors.response.status === 422) {
          setErrors(errors.response.data.errors);
        }
      });
  };

  useEffect(() => {
    fetchPayout();
  }, []);
  return (
    <div className="w-full flex flex-wrap">
      <div className="w-full px-1">
        <div className="card">
          <div className="card-body">
            <h4 className="card-heading">Create Payout Method</h4>{" "}
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-5">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="bank_name"
              >
                Payment Method
              </label>

              <div class="flex">
                <div class="mb-3 xl:w-96">
                  <select
                    class="form-select appearance-none
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding bg-no-repeat
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    aria-label="Default select example"
                    onChange={(e) => setPayoutMethod(e.target.value)}
                  >
                    <option selected>Open this select menu</option>
                    {methods.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.bank_name}({item.account_number})
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="bank_name"
              >
                Total Amount
              </label>
              <p className="">Tk.{total}</p>
            </div>
            <button
              onClick={handleSubmit}
              className="bg-purple-600 py-3 px-4 mb-3 mt-5 w-1/6 flex justify-evenly text-white font-bold rounded right-0"
            >
              Create
            </button>
          </div>
        </div>

        <div className="card mt-5">
          <div className="card-body">
            <h4 className="card-heading">Items</h4>{" "}
            <table className="table">
              <thead>
                <tr>
                  <th className="w-1/6">Order Number</th>
                  <th className="w-1/6">Product Name</th>
                  <th className="w-1/6">Quantity</th>
                  <th className="w-1/6">Cost Price</th>
                  <th className="w-1/6">Line Total</th>
                </tr>
              </thead>
              <tbody>
                  {items?.map((item,index)=>(
                      <tr key={index}>
                        <td>{item.order.order_number}</td>
                        <td>{item.product.name}</td>
                        <td>{item.quantity}</td>
                        <td>{item.product.cost_price}</td>
                        <td>{item.line_total}</td>
                      </tr>
                     
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayoutHistoryCreate;
