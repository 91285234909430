import React,{useState,useEffect} from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import axios from "axios"
const PayoutCreate = () => {
    const {token} = useSelector(state => state.auth);
  const [bankName, setBankName] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [bankRoutingNumber, setBankRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");

  const [errors,setErrors]=useState([])
  
  let history = useHistory();
    const handleSubmit = ()=>{
        // console.log(bankName,bankBranch,bankRoutingNumber,accountNumber,accountName);
        axios.post('payout', {
            bank_name: bankName,
            bank_branch: bankBranch,
            bank_routing_number: bankRoutingNumber,
            account_number:accountNumber,
            account_name:accountName
        }, {
            headers: {
                Authorization: 'Bearer '+token,
                Accept: 'application/json'
            }
        }).then(response => {
            history.push('/payout')
        }).catch(errors => {
            console.log(errors.response);
            if(errors.response.status === 422)
            {
                setErrors(errors.response.data.errors);
            }
        })
    }
  return (
    <div className="w-full flex flex-wrap">
      <div className="w-full px-1">
        <div className="card">
          <div className="card-body">
            <h4 className="card-heading">Create Payout Method</h4>{" "}
       
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-2">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="bank_name"
              >
                Bank Name
              </label>
              <input
                className="appearance-none block w-1/2 bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="bank_name"
                name="bank_name"
                type="text"
                onChange={(e)=>setBankName(e.target.value)}
                
              />
              {/* <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p> */}

              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="bank_branch"
              >
                Bank Branch
              </label>
              <input
                className="appearance-none block w-1/2 bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="bank_branch"
                name="bank_branch"
                type="text" 
                onChange={(e)=>setBankBranch(e.target.value)}
              />
              {/* <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p> */}

              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="bank_routing_number"
              >
                Bank Routing Number
              </label>
              <input
                className="appearance-none block w-1/2 bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="bank_routing_number"
                name="bank_routing_number"
                type="text"
                onChange={(e)=>setBankRoutingNumber(e.target.value)}
               
              />
              {/* <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p> */}

              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="account_name"
              >
                Account Name
              </label>
              <input
                className="appearance-none block w-1/2 bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="account_name"
                name="account_name"
                type="text"
                onChange={(e)=>setAccountName(e.target.value)}
               
              />
              {/* <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p> */}

              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="account_number"
              >
                Account Number
              </label>
              <input
                className="appearance-none block w-1/2 bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="account_number"
                name="account_number"
                type="text"
               onChange={(e)=>{setAccountNumber(e.target.value)}}
              />
              {/* <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p> */}
            </div>

            <button onClick={handleSubmit} className='bg-purple-600 py-3 px-4 mb-3 w-1/6 flex justify-evenly text-white font-bold rounded right-0'>Create</button>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayoutCreate;
