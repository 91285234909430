import React,{useState,useEffect} from 'react'
import { useSelector } from 'react-redux';
import { Link,useHistory } from 'react-router-dom';
import  axios  from 'axios';
const Order = () => {
    const {token} = useSelector(state => state.auth);
    const [orders,setOrders]=useState([]);

    const fetchOrders = ()=>{
        axios.get('/orders', {
            headers: {
                Authorization: 'Bearer '+token,
                Accept: 'application/json'
            }
        }).then(response => {
            console.log("*",response.data);
            setOrders(response.data);
        })
    }

    useEffect(()=>{
        fetchOrders();
    },[])
    return (
        <div className="w-full flex flex-wrap">
        <div className="w-full px-1">
            <div className="card">
                <div className="card-body">
                    <h4 className="card-heading">Order List</h4>
                    <div className="max-w-full overflow-x-auto">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="w-1/5">Order ID</th>
                                    <th className="w-1/5">Thumbnail</th>
                                    <th className="w-1/5">Name</th>
                                    <th className="w-1/5">Quantity</th>
                                    <th className="w-1/5">status</th>
                                    <th className="w-1/5">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders?.map((item,index) => (
                                   
                                    <tr key={index}>
                                        <td className="w-32">{item.order_id}</td>
                                        <td className="w-28">
                                            <img className="w-auto h-20" alt="thumbnail" src={item.product.thumbnail} />
                                        </td>
                                        <td>
                                            <p className="font-bold">{item.product.name}</p>
                                        </td>
                                        <td>
                                            <p className="font-bold">{item.quantity}</p>
                                        </td>

                                        <td>
                                            <p className="font-bold">{item.order.status}</p>
                                        </td>
                                    
                                    
                            
                                        <td className="">
                                            <Link to="#" >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                </svg>
                                            </Link>
                                        
                                        </td>
                                    </tr>
                                  
                                ))}
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
           
        </div>
        
          
 
    </div>
    )
}

export default Order
