import React,{useState,useEffect} from 'react'
import { Link,useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from "axios"
import moment from 'moment'
import Moment from 'react-moment';
const PayoutHistory = () => {

    const { token } = useSelector((state) => state.auth);
    let history = useHistory();

    const [histories,setHistories]=useState([]);

    const fetchPayout = () => {
    axios
      .get("payout", {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.payout_methods);
        console.log("🏆", res.data.payout_history);
        setHistories(res.data.payout_history);

      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetchPayout();
  }, []);
    return (
        <div className="w-full flex flex-wrap">
        <div className="w-full px-1">
            <div className="card">
                <div className="card-body">
                    <h4 className="card-heading">Payout History</h4>  <div className='w-1/6'> 
                   <Link to="/payouthistorycreate" className='bg-purple-600 p-3 w-full flex justify-evenly text-white font-bold rounded right-0'>Create </Link>
                   </div>
                  
                    <div className="max-w-full overflow-x-auto">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="w-1/6">Store Id</th>
                                    <th className="w-1/6">Bank Name</th>
                                    <th className="w-1/6">Bank Branch</th>
                                    <th className="w-1/6">Bank Routing Number</th>
                                    <th className="w-1/6">Total Amount</th>
                                    <th className="w-1/6">Account Number</th>
                                    <th className='w-1/6'>Status</th>
                                    <th className='w-1/6'>Date</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                {histories?.map((item,index) => (
                                   
                                    <tr key={index}>
                                        <td className="w-32">{item.store_id}</td>
                                        <td>
                                            <p className="font-bold">{item.payoutmethod.bank_name}</p>
                                        </td>
                                        <td>
                                            <p className="w-45">{item.payoutmethod.bank_branch}</p>
                                        </td>
                                        <td>
                                            <p className="w-45">{item.payoutmethod.bank_routing_number}</p>
                                        </td>
                                        <td>
                                            <p className="w-45">{item.total_amount}</p>
                                        </td>
                                        <td>
                                            <p className="w-45">{item.payoutmethod.account_number}</p>
                                        </td>
                                        <td>
                                            <p className="w-45">{item.status}</p>
                                        </td>
                                        <td>
                                            <p className="w-45">
                                            <Moment format='YYYY/MM/DD'>{item.created_at}</Moment>
            </p>
                                        </td>
                                       
                                    </tr>
                                  
                                ))}
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
           
        </div>
        
          
 
    </div>
    )
}

export default PayoutHistory
