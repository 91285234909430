import axios from 'axios';
import React from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import bgimge from '../login_page_bd.png'
import { setLoader } from '../store/loaderSlice';

import { setToken } from '../store/authSlice';

export default function Login() {

    const [email, setEmail] = React.useState();
    const [password, setPassword] = React.useState();
    const [message, setMessage] = React.useState();
    const [errors, setErrors] = React.useState({});
    const [errormsg,setErrorMsg] = React.useState("")

    const dispatch = useDispatch();

    let history = useHistory();

    const handleSubmit = () => {
        setErrors(null);
        setMessage(null);
        axios.post('/attempt', {email: email, password: password}, {
            Headers: {
                Accept: 'application/json'
            }
        }).then(response => {
            reactLocalStorage.set('_token', response.data._token)
            dispatch(setToken(response.data._token));
            console.log(response.data);
            history.push('/dashboard');
            dispatch(setLoader(true));
        }).catch(error => {
            if(error.response.status == 422)
            {
                setErrors(error.response.data.errors)
            }

            if(error.response.status == 404)
            {
                setMessage(error.response.data.message)
            }
            if(error.response.status === 503)
            {
                setErrorMsg(error.response.data.message)
            }
            console.log(error.response.data.message);
        })
    }

    console.log(errors);
    return (
        <div className="w-4/5 bg-white h-4/5 drop-shadow rounded flex flex-wrap">
            <div className="w-1/2 h-full flex items-center">
                <img src={bgimge} alt="bacground image" className="w-full h-auto px-10" />
            </div>

            <div className="w-1/2 h-full flex items-center flex-wrap">
                <div className="w-full">
                    <h2 className="text-3xl font-bold w-full mb-4">Sign In</h2>

                    <div className="flex flex-col my-4">
                        {message ? (
                            <p className="w-4/5 bg-red-200 p-2 border border-red-400 rounded">{message}</p>
                        ) : ''}
                        {
                            errormsg ? (<p className="w-4/5 bg-red-200 p-2 border border-red-400 rounded">{errormsg}</p>):''
                        }
                        
                    </div>

                    <div className="flex flex-col my-4">
                        <label className="text-lg">Email</label>
                        <input onChange={(e) => setEmail(e.target.value)} value={email} className="border border-gray-200 text-xl p-2 w-4/5" type="text" placeholder="Email" />
                        <p className="text-red-400">{errors?.email}</p>
                        
                    </div>

                    <div className="flex flex-col my-4">
                        <label className="text-lg">Password</label>
                        <input onChange={(e) => setPassword(e.target.value)} value={password} className="border border-gray-200 text-xl p-2 w-4/5" type="password" placeholder="Password" />
                        <p className="text-red-400">{errors?.password}</p>
                    </div>
                    <div>
                        <button onClick={() => handleSubmit()} className="bg-blue-500 px-6 py-2 rounded text-white">Login</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
