import axios from 'axios';
import React,{Fragment, useState} from 'react'
import { useSelector } from 'react-redux';
import { Link,useHistory } from 'react-router-dom';
import Modal from 'react-modal';
export default function Product() {

    const [products, setProducts] = React.useState([]);

    const [product,setProduct]= useState([])

    const [stock,setStock]=useState('');
    const [instock,setInStock]=useState(true)

    
    const {token} = useSelector(state => state.auth);
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = React.useState([]);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => {
        setOpen(false);
        setProduct('');
    };
    
    let history = useHistory();
    
    const showProduct = (id)=>{
        setOpen(true);
        fetchStock(id);
        setStock('');
    }

    const fetchStock = (id)=>{
        axios.get(`/products/${id}`, {
            headers: {
                Authorization: 'Bearer '+token,
                Accept: 'application/json'
            }
        }).then(res => {
            setProduct(res.data);
            setStock(res.data.stock);
            setInStock(res.data.in_stock);
            // console.log(res.data.stock);
        })
    }

    const fetchProducts = ()=>{
        axios.get('/products', {
            headers: {
                Authorization: 'Bearer '+token,
                Accept: 'application/json'
            }
        }).then(response => {
            console.log(response.data);
            setProducts(response.data);
        })
    }

    const handleStockSubmit = (e,id)=>{
        e.preventDefault();
        
        axios.post(`/products/${id}`, {
           stock: stock,
           in_stock: instock,        
       }, {
           headers: {
               Authorization:'Bearer '+token,
               Accept: 'application/json'
           }
       }).then(response => {
            fetchProducts();
        //    history.push('/products')
            setOpen(false);
       }).catch(errors => {
           console.log(errors.response);
           if(errors.response.status === 422)
           {
               setErrors(errors.response.data.errors);
           }
       })
        console.log(instock);

    
    }



    React.useEffect(() => {
        fetchProducts()
    }, [])

    
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '500px',
        },
      };
    return (
        <div className="w-full flex flex-wrap">
            <div className="w-full px-1">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-heading">Product List</h4>
                        <div className="max-w-full overflow-x-auto">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Product SKU</th>
                                        <th>Thumbnail</th>
                                        <th>Name</th>
                                        <th>Stock</th>
                                        <th>In Stock</th>
                                        <th>Status</th>
                                        <th>Price</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products?.data?.map((item,index) => (
                                        <Fragment key={index}>
                                        <tr>
                                        <td className="w-32">{item.SKU}</td>
                                        <td className="w-28">
                                            <img className="w-auto h-20" alt="thumbnail" src={item.thumbnail} />
                                        </td>
                                        <td>
                                            <p className="font-bold">{item.name}</p>
                                        </td>
                                        <td>
                                            <p className="font-bold">{item.stock}</p>
                                        </td>
                                      {/* {item.in_stock === 1?  
                                            <td>
                                                <p className="font-bold">Available</p>
                                            </td>
                                            :  
                                            <td>
                                                <p className="font-bold">No Available</p>
                                            </td>
                                        }  */}
                                        {item.in_stock === true ? 
                                            <td>
                                                <p className="font-bold">In Stock</p>
                                            </td> :
                                             <td>
                                                <p className="text-red-600 font-bold">Out of Stock</p>
                                            </td>
                                        }
                                        <td>
                                            <p className="font-bold">{item.status}</p>
                                        </td>
                                        <td>{item.cost_price} TK.</td>
                                        <td className="">
                                            <Link to="#" onClick={()=>showProduct(item.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                </svg>
                                            </Link>
                                           
                                        </td>
                                    </tr>
                                        </Fragment>
                                    ))}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
               
            </div>
            <Modal isOpen={open}  style={customStyles}  onRequestClose={onCloseModal}  ariaHideApp={false}>
                <h2  className="block text-gray-700 text-sm font-bold mb-2">Update Product Stock</h2>
                <form action="">
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" for="stock">Stock</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => setStock(e.target.value)}  id="stock" name="stock" type="text" placeholder="Stock" value={stock}/>
                    </div>
            
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" for="in_stock"> In Stock</label>
                        <select className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => setInStock(e.target.value)} placeholder="Stock" name="in_stock" id="in_stock" >
                            <option disabled  selected>Select</option>
                            <option value="1">Available</option>
                            <option value="0">Not Available</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <button onClick={(e) => handleStockSubmit(e,product.id)} className="bg-green-600 p-3  flex justify-evenly text-white font-bold rounded">update</button>
                    </div>
                  
                </form>
               <button onClick={onCloseModal} className="bg-purple-600 p-3  flex justify-evenly text-white font-bold rounded">close</button>
            </Modal>
        </div>
    )
}
