import React,{useState,useEffect}  from 'react'
import { useSelector } from 'react-redux';
import { Link,useHistory } from 'react-router-dom';
import  axios  from 'axios';
const ProductReview = () => {
    const {token} = useSelector(state => state.auth);
    const [reviews,setReviews]=useState([]);
    
    const fetchReviews = ()=>{
        axios.get('/reviews', {
            headers: {
                Authorization: 'Bearer '+token,
                Accept: 'application/json'
            }
        }).then(response => {
            console.log("💥",response.data.data);
            setReviews(response.data.data);
        })
    }

    useEffect(()=>{
        fetchReviews();
    },[])

    return (
        <div className="w-full flex flex-wrap">
        <div className="w-full px-1">
            <div className="card">
                <div className="card-body">
                    <h4 className="card-heading">Product Review</h4>
                    <div className="max-w-full overflow-x-auto">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="w-1/5">Product Name</th>
                                    <th className="w-1/5">Rating</th>
                                    <th className="w-1/5">Review</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reviews?.map((item,index) => (
                                   
                                    <tr key={index}>
                                        <td className="w-32">{item.product.name}</td>
                                        <td>
                                            <p className="font-bold">{item.rating}</p>
                                        </td>
                                        <td>
                                            <p className="w-45">{item.review}</p>
                                        </td>
                                    
                                    </tr>
                                  
                                ))}
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
           
        </div>
        
          
 
    </div>
    )
}

export default ProductReview
