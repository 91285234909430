import axios from 'axios';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Spiner from '../components/Spiner';
import { setUser } from '../store/authSlice';
import { setLoader } from '../store/loaderSlice';

export default function AppLayout({children}) {

    let history = useHistory();

    const dispach = useDispatch();

    const [sidebar, setSidebar] = React.useState(true);

    // const [auth, setAuth] = React.useState();

    const _token = reactLocalStorage.get('_token', true);

    const handleSidebar = () => {
        setSidebar(!sidebar);
    }

    const { token } = useSelector((state) => state.auth)
    const { load } = useSelector((state) => state.loader)

    React.useEffect(() => {
        if(_token == null)
        {
            history.push('/signin');
        }

        axios.get('/profile', {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer '+_token
            }
        }).then(response => {
            // console.log(response);
            dispach(setUser(response.data));
            dispach(setLoader(false));
        }).catch(error => {
            history.push('/signin');
        })

        

    }, []);

    return (
        <>
        {load ? (
            <>
                <Spiner />
            </>
        ) : (
            <div className="w-full h-screen bg-gray-100 relative">
                <Navbar handleSidebar={handleSidebar} />
                <div className="w-full flex flex-wrap">
                    <div className={sidebar ? 'w-1/6 h-screen fixed bg-white shadow sidebar-animation' : 'w-20 absolute h-screen bg-white shadow'}>
                        <Sidebar sidebar={sidebar} />
                    </div>
                    <div className={sidebar ? 'w-5/6 px-6 mt-14 pt-4 sidebar-animation ml-auto' : 'w-full ml-20 px-6 mt-14 pt-4'}>
                        {children}
                    </div>
                </div>
            </div>
        )}
            
        </>
    )
}
