import React from 'react'
import { Line, Pie } from 'react-chartjs-2';

export default function Home() {
    const dataOne = {
        labels: ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
        datasets: [
                {
                label: '# of Sales',
                data: [12, 19, 3, 5, 2, 3, 8],
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
                },
            ],
    };

    const dataTwo = {
    labels: ['Product One', 'Product Two', 'Product Three', 'Product Four', 'Product Five', 'Product Six'],
    datasets: [
        {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
        },
    ],
    };
    
    const options = {
        scales: {
          y: {
            beginAtZero: true
          }
        }
    };

    return (
        <>
        <div className="w-full flex flex-wrap">
            <div className="w-4/6 px-1">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-heading">Sales Chart</h4>
                        <Line data={dataOne} options={options} />
                    </div>
                </div>
            </div>
            <div className="w-2/6 px-1">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-heading">Best Selling Products</h4>
                        <Pie data={dataTwo} />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
