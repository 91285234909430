import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom'
import { reactLocalStorage } from 'reactjs-localstorage';
import { setToken, setUser } from '../store/authSlice';

export default function Navbar({handleSidebar}) {

    const dispatch = useDispatch();
    let history = useHistory();

    const { user } = useSelector(state => state.auth);


    const handleLogout = () => {
        dispatch(setToken(null))
        dispatch(setUser({}));

        reactLocalStorage.remove('_token');
        history.push('signin')
    }

    React.useEffect(() => {
        console.log(user);
    }, []);

    return (
        <div className="w-full bg-white h-14 flex justify-between z-50 absolute shadow">
            <div className="mx-4 flex items-center">
                <img className="h-10 w-auto" src="/logo.png" alt="logo" />
                
                <button className="ml-4" onClick={() => handleSidebar()}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg></button>
            </div>
            <div className="flex items-center">
                <div className="mx-4 text-blue-400 relative">
                    <div className="bg-red-600 w-3 h-3 rounded-full absolute -mt-1 ml-3"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                    </svg>
                </div>
                <div className="mx-4 flex items-center relative profile">
                    <a href="#" className="flex items-center">
                        <div className="w-10 h-10 bg-gray-300 rounded-full mr-2 cursor-pointer"></div>
                        <h3 className="text-sm font-bold text-gray-700">{user.name}</h3>
                    </a>
                    <div className="profile-menu absolute w-64 mt-40 pt-5 -ml-24">
                        <ul className="w-full bg-white py-4 px-2 rounded shadow">
                            <li>
                                <NavLink to="/profile" className="block p-2 px-4">
                                    Profile
                                </NavLink>
                            </li>
                            <li>
                                <button onClick={() => handleLogout()} type="button" className="block p-2 px-4">Logout</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
