import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token: null,
    user: {}
}

export const authSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    removeToken: (state) => {
        state.token = null
    },
    setUser: (state, action) => {
        state.user = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setToken, removeToken, setUser } = authSlice.actions

export default authSlice.reducer