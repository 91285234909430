import React from 'react'

export default function Profile() {
    return (
        <div className="w-full flex flex-wrap">
            <div className="w-1/2 px-1">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-heading">Profile Settings</h4>
                        <div className="row">
                            <div className="w-1/3 px-1">
                                <div className="input-group">
                                    <label>Name</label>
                                    <input type="name" className="input-field" />
                                </div>
                            </div>
                            <div className="w-1/3 px-1">
                                <div className="input-group">
                                    <label>Email</label>
                                    <input type="name" className="input-field" />
                                </div>
                            </div>
                            <div className="w-1/3 px-1">
                                <div className="input-group">
                                    <label>Phone</label>
                                    <input type="name" className="input-field" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="w-1/2 px-1">
                                <div className="input-group">
                                    <label>Password</label>
                                    <input type="name" className="input-field" />
                                </div>
                            </div>
                            <div className="w-1/2 px-1">
                                <div className="input-group">
                                    <label>Confirm Password</label>
                                    <input type="name" className="input-field" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="w-full px-1 mt-4">
                                <button className="float-right bg-green-400 text-white px-8 py-2 rounded hover:bg-green-600">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-1/2 px-1">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-heading">Store Settings</h4>
                        <div className="row">
                            <div className="w-full px-1">
                                <div className="input-group">
                                    <label htmlFor="logo" className="border-dotted w-full h-40 border-4 flex justify-center items-center">Upload Logo</label>
                                    <input className="hidden" id="logo" type="file" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
